import React, { useContext, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import * as Yup from 'yup'
import { ErrorMessage, Field, Form, withFormik } from 'formik'
import { palette } from '@zcool/theme'
import Button from '@zcool/button'
import { Box, Text } from 'components/ui/base'
import ApplySucceed from 'components/ui/apply-succeed'
import { GlobalContext } from 'shared/context'
import { showModal, toast } from 'shared/actions'
import { phoneReg } from 'helpers/regex'
import { apply, ApplyRequestParams, From } from 'api/form'
import { ApplyFormProps, ExtraParams } from './types'
import useIndustriesAndRegions from './useIndustriesAndRegions'
import { PlusAiApplyCommitClick, PlusApplyCommitClick, safeExecute } from 'helpers/analyze-meitu'

const FormContainer = styled(Form)`
  width: 580px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .apply__form-item {
    display: inline-block;
    position: relative;
    font-size: 14px;

    span {
      position: absolute;
      top: 16px;
      right: 24px;
      color: ${palette.primary};
    }

    &.placeholder .select {
      color: ${palette.pewter};
    }
  }

  .apply__form-input {
    background-color: #f1f1f1;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    border: none;

    &.select {
      appearance: none;
      border: none;
      border-radius: 0;
      background: url(/icon-dropdown.svg) 97% 50% no-repeat #f1f1f1;
      background-size: 9px 5px;
      letter-spacing: 1px;
    }
  }

  .apply__form-error {
    background-color: #ffdfde;

    > .apply__form-input {
      background-color: transparent;
    }
  }

  .row {
    margin-bottom: 16px;
  }

  .half {
    width: 280px;
  }

  .apply__form-item + .apply__form-item {
    margin-left: 20px;
  }

  .full {
    width: 100%;
  }

  button.full {
    max-width: 100%;
    border-radius: 0;
  }
`

const FormInput = ({
  name,
  max = 100,
  className,
  error,
  id,
}: {
  name: string
  max?: number
  className?: string
  error: boolean
  id: string
}) => (
  <div className={`apply__form-item ${error ? 'apply__form-error' : ''} ${className}`}>
    <FormattedMessage id={id}>
      {(msg: string) => (
        <Field
          type="text"
          autoComplete="off"
          className="apply__form-input"
          name={name}
          maxLength={max}
          placeholder={msg}
        />
      )}
    </FormattedMessage>
    <ErrorMessage name={name}>{(msg) => <span>{msg}</span>}</ErrorMessage>
  </div>
)

const FormSelect = ({
  name,
  className,
  error,
  id,
  options,
}: {
  name: string
  className?: string
  error: boolean
  id: string
  options: Array<{ name: string; id: string }>
}) => (
  <div className={`apply__form-item ${error ? 'apply__form-error' : ''} ${className}`}>
    <FormattedMessage id={id}>
      {(msg: string) => (
        <Field name={name} className="apply__form-input select" component="select">
          <option key={`${name}-select-0`} value="" disabled={true}>
            {msg}
          </option>
          {options.map((option) => (
            <option key={`${name}-${option.id}`} value={option.id}>
              {option.name}
            </option>
          ))}
        </Field>
      )}
    </FormattedMessage>
    <ErrorMessage name={name}>{(msg) => <span>{msg}</span>}</ErrorMessage>
  </div>
)

const required: any = <FormattedMessage id="errors.required" />

const incorrect: any = <FormattedMessage id="errors.incorrect-format" />

const ApplyFormSchema = Yup.object().shape({
  name: Yup.string().trim().required(required),
  phone: Yup.string().trim().matches(phoneReg, incorrect).required(required),
  jobTitle: Yup.string().trim().required(required),
  regionId: Yup.string().trim().required(required),
  companyName: Yup.string().trim().required(required),
  companyEmail: Yup.string().trim().email(incorrect),
  companyType: Yup.string().trim().required(required),
  industryId: Yup.string().trim().required(required),
})

export const BaseApplyForm = withFormik<
  {
    afterSubmit: (result: boolean, message: React.ReactNode) => void
    industries: Array<any>
    regions: Array<any>
    extraParams?: ExtraParams
    applyFunc?: typeof apply
  },
  {
    name: string
    phone: string
    jobTitle: string
    regionId: string
    companyName: string
    companyEmail: string
    companyType?: string
    industryId: string
  },
  any
>({
  mapPropsToValues: () => {
    return {
      name: '',
      phone: '',
      jobTitle: '',
      regionId: '',
      companyName: '',
      companyEmail: '',
      companyType: '',
      industryId: '',
    }
  },
  validationSchema: ApplyFormSchema,
  handleSubmit: (values, { props, setSubmitting }) => {
    const { afterSubmit, applyFunc = apply } = props

    const params: ApplyRequestParams = Object.assign({}, values, props.extraParams)

    applyFunc(params)
      .then(({ result, status, message }) => {
        if (status === false) {
          showModal('ALERT', {
            content: (
              <>
                <Text color="pewter" align="left" mb={8} block={true}>
                  <FormattedMessage id="form.apply.alert.title" />
                </Text>
                <Text color="pewter" align="left" mb={8} block={true}>
                  <FormattedMessage id="from.apply.feedback-already-submitted" />
                </Text>
                <Text color="pewter" align="left" block={true}>
                  <FormattedMessage id="from.apply.feedback-400" />
                </Text>
              </>
            ),
            okText: <FormattedMessage id="actions.i.see" />,
          })
        } else {
          afterSubmit(result, message)
          const { regionId } = values
          const { industryId } = values
          const { companyType } = values
          // const regionName = regions?.find((item) => String(item.id) === regionId)?.name
          // const companyItem = industries.find((item) => String(item.id) === companyType)
          // const industryName = companyItem?.items?.find((item) => String(item.id) === industryId)
          // console.warn(values, regions, regionName, 'com', industries, companyItem, 'in', companyItem, industryName)
          safeExecute(() => {
            if (params.from === From.AIGC) {
              PlusAiApplyCommitClick({
                url: window.location.href,
                referrer: document.referrer,
                real_name: values.name,
                phone_num: values.phone,
                company_name: values.companyName,
                company_email: values.companyEmail,
                job: values.jobTitle,
                company_address: regionId,
                company_type: companyType,
                company_sub_type: industryId,
              })
            } else {
              PlusApplyCommitClick({
                url: window.location.href,
                referrer: document.referrer,
                real_name: values.name,
                phone_num: values.phone,
                company_name: values.companyName,
                company_email: values.companyEmail,
                job: values.jobTitle,
                company_address: regionId,
                company_type: companyType,
                company_sub_type: industryId,
              })
            }
          })
        }

        setSubmitting(false)
      })
      .catch(() => {
        afterSubmit(false, <FormattedMessage id="errors.wrong" />)
        setSubmitting(false)
      })
  },
  displayName: 'BaseApplyForm',
})((props) => {
  const { touched, errors, values, isSubmitting, handleChange, handleBlur, setFieldValue, industries, regions } = props

  const subIndustries = (
    industries.find((i) => i.id === parseInt(values.companyType, 10)) || {
      items: [],
    }
  ).items

  return (
    <FormContainer>
      <div className="row">
        <FormInput
          className="half"
          name="name"
          id="form.apply.placeholder.name"
          max={50}
          error={errors.name && touched.name}
        />
        <FormInput
          className="half"
          name="phone"
          id="form.apply.placeholder.phone"
          error={errors.phone && touched.phone}
        />
      </div>
      <div className="row">
        <FormInput
          className="half"
          name="companyName"
          max={50}
          id="form.apply.placeholder.company-name"
          error={errors.companyName && touched.companyName}
        />
        <FormInput
          className="half"
          name="companyEmail"
          max={50}
          id="form.apply.placeholder.company-email"
          error={errors.companyEmail && touched.companyEmail}
        />
      </div>
      <div className="row">
        <FormInput
          className="half"
          name="jobTitle"
          max={20}
          id="form.apply.placeholder.job-title"
          error={errors.jobTitle && touched.jobTitle}
        />

        <FormSelect
          className={`half ${values.regionId === '' ? 'placeholder' : ''}`}
          name="regionId"
          id="form.apply.placeholder.company-address"
          error={errors.regionId && touched.regionId}
          options={regions}
        />
      </div>
      <div className="row">
        <div
          className={`apply__form-item half ${errors.companyType && touched.companyType ? 'apply__form-error' : ''} ${
            values.companyType === '' ? 'placeholder' : ''
          }`}
        >
          <FormattedMessage id="form.apply.placeholder.company-type">
            {(msg: string) => (
              <select
                name="companyType"
                className="apply__form-input select"
                value={values.companyType}
                onChange={(e) => {
                  handleChange(e)
                  setFieldValue('industryId', '')
                }}
                onBlur={handleBlur}
              >
                <option key="companyType-select-0" value="" disabled={true}>
                  {msg}
                </option>
                {industries.map((option) => (
                  <option key={`companyType-${option.id}`} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            )}
          </FormattedMessage>
          <ErrorMessage name="companyType">{(msg) => <span>{msg}</span>}</ErrorMessage>
        </div>
        <FormSelect
          className={`half ${values.industryId === '' ? 'placeholder' : ''}`}
          name="industryId"
          id="form.apply.placeholder.company-subtype"
          error={errors.industryId && touched.industryId}
          options={subIndustries}
        />
      </div>
      <Button type="primary" htmlType="submit" size="large" className="full" disabled={isSubmitting}>
        <FormattedMessage id="actions.form.submit" />
      </Button>
    </FormContainer>
  )
})

export default function ApplyForm({ background = '#3c3c3c', succeedBackground, titleColor }: ApplyFormProps) {
  const { currentUser, updateCurrentUser } = useContext(GlobalContext)
  const [isSuccess, setSuccess] = useState(currentUser ? currentUser.status === 0 : false)

  const { industries, regions } = useIndustriesAndRegions()

  function handleSubmit(result: boolean, message: React.ReactNode) {
    if (result) {
      setSuccess(result)
      if (currentUser) {
        currentUser.status = 0
        updateCurrentUser(currentUser)
      } else {
        updateCurrentUser({ status: 0, anonymous: true })
      }
    } else {
      toast(message)
    }
  }

  return (
    <Box background={isSuccess && succeedBackground ? succeedBackground : background} py={48}>
      {isSuccess ? (
        <ApplySucceed titleColor={titleColor} />
      ) : (
        <>
          <Text as="h3" color={titleColor} size="24" mb={16} align="center" block={true}>
            <FormattedMessage id="actions.apply" />
          </Text>

          <Text as="p" size={14} mb={16} color="gray" align="center" block={true}>
            <FormattedMessage id="from.apply.description-0" />
            <br />
            <FormattedMessage id="from.apply.description-1" />
          </Text>
          <BaseApplyForm afterSubmit={handleSubmit} industries={industries} regions={regions} />
        </>
      )}
    </Box>
  )
}
