import API from './index'
import { API_AIGC_APPLY, INDEX_APPLY, INDEX_INDUSTRIES, INDEX_REGIONS } from './endpoints'

/**
 * 商机来源
 */
export enum CustomerSource {
  /**
   * plus线上申请
   */
  PLUS_ONLINE_APPLY = 20,
}

export enum From {
  AIGC = 3,
}

export type ApplyRequestParams = {
  name: string
  phone: string
  companyName: string
  companyEmail: string
  jobTitle: string
  industryId: string
  regionId: string
  customerSource?: CustomerSource
  from?: From
}

/**
 * 提交申请
 */
export function apply({
  name,
  phone,
  companyName,
  companyEmail,
  jobTitle,
  industryId,
  regionId,
  customerSource,
  from,
}: ApplyRequestParams) {
  return API.post(INDEX_APPLY, {
    name,
    phone,
    company_name: companyName,
    company_email: companyEmail,
    position: jobTitle,
    industry_id: industryId,
    region_id: regionId,
    customer_source: customerSource,
    from,
  })
}

export function aigcApply({
  name,
  phone,
  companyName,
  companyEmail,
  jobTitle,
  industryId,
  regionId,
  customerSource,
  from,
}: ApplyRequestParams) {
  return API.post(API_AIGC_APPLY, {
    name,
    phone,
    company_name: companyName,
    company_email: companyEmail,
    position: jobTitle,
    industry_id: industryId,
    region_id: regionId,
    customer_source: customerSource,
    from,
  })
}

/**
 * 获取企业类型
 */
export function getIndustries() {
  return API.get(INDEX_INDUSTRIES, { useCache: true })
}

/**
 * 获取地区信息
 */
export function getRegions() {
  return API.get(INDEX_REGIONS, { useCache: true })
}
