import { getIndustries, getRegions } from 'api/form'
import { useEffect, useState } from 'react'

export default function useIndustriesAndRegions() {
  const [industries, setIndustries] = useState([])
  const [regions, setRegions] = useState([])

  useEffect(() => {
    Promise.all([getIndustries(), getRegions()]).then(([IndustriesResponse, regionsResponse]) => {
      if (IndustriesResponse && IndustriesResponse.result) {
        setIndustries(IndustriesResponse.data)
      }

      if (regionsResponse && regionsResponse.result) {
        setRegions(regionsResponse.data)
      }
    })
  }, [])

  return {
    industries,
    regions,
  }
}
