import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Flex from '@zcool/flex'
import { Box, Text } from 'components/ui/base'
import Icon from 'components/ui/icon'

const ApplyContainer = styled.div`
  text-align: center;

  section {
    width: 580px;
    margin: auto;
    text-align: center;
  }
`

export default function ApplySucceed({ titleColor = 'black3' }: { titleColor: string }) {
  return (
    <ApplyContainer>
      <FormattedMessage id="actions.apply">
        {(msg) => (
          <Text as="h4" size={24} color={titleColor} mt={24} mb={24}>
            {msg}
          </Text>
        )}
      </FormattedMessage>
      <Box py={60}>
        <Flex valign="center" halign="center">
          <Icon glyph="success" size={48} />
          <FormattedMessage id="from.apply.succeed">
            {(msg) => (
              <Text size={18} ml={16} color="black3" weight="medium">
                {msg}
              </Text>
            )}
          </FormattedMessage>
        </Flex>
        <Text color="pewter" mt={36}>
          <FormattedMessage id="from.apply.feedback-3q">{(msg) => msg}</FormattedMessage>
          <br />
          <br />
          <FormattedMessage id="from.apply.feedback-concat-shortly">{(msg) => msg}</FormattedMessage>
        </Text>
      </Box>
      <FormattedMessage id="from.apply.feedback-400">
        {(msg) => (
          <Text color="pewter" mt={16}>
            {msg}
          </Text>
        )}
      </FormattedMessage>
    </ApplyContainer>
  )
}
